import React from "react";
import HomeScreen from "components/Showcases-full-screen/showcases-full-screen";

const Home = () => {
    return (

        <HomeScreen />

    );
};

export const Head = () => {
    return (
        <>
            <title>Valvie</title>
        </>
    )
}

export default Home;